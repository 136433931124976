import React from 'react'
import ReactDOM from 'react-dom'
import { App } from 'App'

import initializeSentry from 'services/Sentry'
import { ThemeProvider } from 'services/providers/ThemeContext'
import { Toaster } from 'shadcn-components/ui/toaster'

initializeSentry()

ReactDOM.render(
  <ThemeProvider>
    <App />
    <Toaster />
  </ThemeProvider>,
  document.getElementById('root')
)

import React, { useState, useEffect, useContext } from 'react'

import { Card, CardContent } from 'shadcn-components/ui/card'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from 'shadcn-components/ui/tabs'
import { useToast } from 'shadcn-components/ui/use-toast'
import { Skeleton } from 'shadcn-components/ui/skeleton'

import { useQuery, useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import apiSellers from 'services/api/Seller'
import BrandCreatives from './BrandCreatives'
import VideoCreatives from './VideoCreatives'
import apiBrands from 'services/api/Brand'
import { sellerProfileId } from 'services/Utils'
import axios from 'axios'
import ThemeContext from 'services/providers/ThemeContext'

const Creatives = ({ user }) => {
  const { lightMode } = useContext(ThemeContext)
  const { toast } = useToast()
  const params = useParams()
  const queryClient = useQueryClient()
  const history = useHistory()
  const [horizontalTabs, setHorizontalTabs] = useState(
    params.type ? params.type : 'brand'
  )
  const [primarySeller, setPrimarySeller] = useState([])
  const primarySellerPublicId = primarySeller?.public_id
  const [brands, setBrands] = useState([])
  const [products, setProducts] = useState([])
  const [queryParam, setQueryParam] = useState({
    page: 1,
    per_page: 10,
  })
  const [failedSpec, setFailedSpec] = useState([])
  const [uploadingLogo, setUploadingLogo] = useState(false)
  const [updatingLogo, setUpdatingLogo] = useState(false)
  const [uploadingMedia, setUploadingMedia] = useState(false)
  const [updatingMedia, setUpdatingMedia] = useState(false)
  const [productsDataObject, setProductsDataObject] = useState({})

  useEffect(() => {
    setPrimarySeller(user.primary_seller)
  }, [user])

  const {
    data: brandsObject,
    isFetching: brandsIsLoading,
    brandsRefetch,
  } = useQuery(
    ['GET/sellers/', primarySellerPublicId, '/brands', queryParam],
    () => apiSellers.brands(primarySellerPublicId, queryParam),
    {
      staleTime: 1440 * 1440 * 1000, // 24 hrs
      enabled: !!primarySellerPublicId, // The query will not execute until the primarySellerId exists
      onSuccess: (brandsObject) => {
        setBrands(brandsObject?.data.brands)
      },
    }
  )

  useEffect(() => {
    const brandsCache = queryClient.getQueryData([
      'GET/sellers/',
      primarySellerPublicId,
      '/brands',
      queryParam,
    ])
    if (brandsCache && brandsCache.data?.brands) {
      setBrands(brandsCache?.data.brands)
    }
  }, [queryClient, primarySellerPublicId, queryParam])

  useEffect(() => {
    if (uploadingMedia) {
      const timer = () =>
        setTimeout(() => {
          toast({
            variant: 'default',
            description:
              'Hang tight... this can take a while for large video files.',
          })
        }, 10000)
      const timerId = timer()
      return () => {
        clearTimeout(timerId)
      }
    }
  }, [uploadingMedia])

  const handleBrandUpdate = async (data) => {
    var response
    var commonData = {
      marketplace_marketplace_id: primarySeller.primary_marketplace_id,
      seller_selling_partner_id: primarySeller.selling_partner_id,
      profile_profile_id: sellerProfileId(primarySeller),
      brand_entity_id: data.brand_entity_id,
    }
    var brandData = {}
    switch (data.action) {
      case 'update_brand_logo':
        setUpdatingLogo(true)
        brandData = JSON.stringify([
          { ...commonData, ...{ logo_url: data.url } },
        ]) // note sending array
        response = await apiBrands.post(brandData).then((res) => {
          return res
        })
        break
      case 'upload_brand_logo':
        let fileSize = data.file?.size / 1024 / 1024 // in MiB
        if (fileSize > 1) {
          toast({
            variant: 'destructive',
            description: 'File size exceeds 1MB',
          })
          break
        }
        setUploadingLogo(true)
        brandData = new FormData()
        brandData.append('file', data.file)
        brandData.append(
          'data',
          JSON.stringify({ ...commonData, ...{ media_type: 'brandLogo' } })
        )
        response = await apiBrands.logoUpload(brandData).then((res) => {
          return res
        })
        break
      case 'upload_brand_media':
        setUploadingMedia(true)

        try {
          response = await apiBrands.mediaLocation({
            ...commonData,
            filename: data.file.name,
          })

          if (!response?.data?.upload_location) {
            throw new Error('Failed to fetch media location.')
          }

          const uploadLocation = response.data.upload_location
          const filename = data.file.name

          const axiosInstance = axios.create()
          axiosInstance.defaults.headers.common = {} // s3 bucket PUT requires clear/default headers
          response = await axiosInstance
            .put(uploadLocation, data.file, {
              withCredentials: false, // important
              headers: {
                'Content-Type': 'video/quicktime',
              },
            })
            .then((res) => {
              return res
            })
            .catch((err) => {
              return null
            })

          if (!response || response?.status > 300) {
            throw new Error('Failed to upload video.')
          }

          response = await apiBrands.mediaUploadComplete({
            ...commonData,
            media_type: 'video',
            product_asins: data.product_asins,
            upload_location: uploadLocation,
            version_id: '',
            filename: filename,
          })

          if (!response) {
            toast({
              variant: 'destructive',
              description: 'Failed to save video.',
            })
          }
        } catch (error) {
          toast({
            variant: 'destructive',
            description: error?.message,
          })
        }
        break
      case 'update_brand_media':
        setUpdatingMedia(true)
        brandData = JSON.stringify({
          ...commonData,
          ...{
            media_id: data.media_id,
            creative_asset_id: data.creative_asset_id,
            state: data.state,
            product_asins: data.product_asins,
            media_type: 'video',
          },
        })
        response = await apiBrands.media(brandData).then((res) => {
          return res
        })
        break
      default:
      // do nothing
    }
    if (response && response.status < 300) {
      queryClient.invalidateQueries([
        'GET/sellers/',
        primarySellerPublicId,
        '/brands',
        queryParam,
      ])
      toast({
        variant: 'success',
        description: response?.data?.message,
      })
      if (['upload_brand_media', 'update_brand_media'].includes(data.action)) {
        history.push('/admin/creatives/video')
      }
    } else {
      if (response) {
        const failedSpecList = response?.data?.failedSpec?.specifications?.map(
          (item) => {
            const errType = item?.stringId
              .replace('al-spec-', '')
              .split('-')
              .join(' ')
            return {
              type: errType,
              value: item?.actualValue || '',
              expectedValues: item?.arguments,
            }
          }
        )

        setFailedSpec(failedSpecList)
        toast({
          variant: 'destructive',
          description: response?.data?.message,
        })
      }
    }
    setUploadingLogo(false)
    setUpdatingLogo(false)
    setUploadingMedia(false)
    setUpdatingMedia(false)
  }

  const {
    data: productsObject,
    isFetching: productsIsLoading,
    productsRefetch,
  } = useQuery(
    [
      'GET/sellers/',
      primarySellerPublicId,
      '/products',
      { page: 1, per_page: 1000 },
    ],
    () =>
      apiSellers.products(primarySellerPublicId, {
        'filter[or]': JSON.stringify([
          { field: 'condition', op: 'eq', value: 'New' },
          { field: 'quantity', op: 'gt', value: 0 },
        ]),
        page: 1,
        per_page: 1000,
      }),
    {
      staleTime: 1440 * 1440 * 1000, // 24 hrs
      enabled: !!primarySellerPublicId, // The query will not execute until the primarySellerId exists
      onSuccess: (productsObject) => {
        setProducts(productOptions(productsObject?.data.products))
        setProductsDataObject(productsObject?.data.products)
      },
    }
  )

  useEffect(() => {
    const productsCache = queryClient.getQueryData([
      'GET/sellers/',
      primarySellerPublicId,
      '/products',
      {
        'filter[or]': JSON.stringify([
          { field: 'condition', op: 'eq', value: 'New' },
          { field: 'quantity', op: 'gt', value: 0 },
        ]),
        page: 1,
        per_page: 1000,
      },
    ])
    if (productsCache && productsCache.data?.products) {
      setProducts(productOptions(productsCache?.data.products))
      setProductsDataObject(productsCache?.data.products)
    }
  }, [queryClient, primarySellerPublicId])

  const productOptions = (prods) => {
    const screenWidth = window.innerWidth
    let maxLength

    // Adjust length based on screen width
    if (screenWidth < 640) {
      maxLength = 10
    } else if (screenWidth < 1024) {
      maxLength = 20
    } else {
      maxLength = 45
    }
    return prods?.map((p) => {
      const trimmedProductName =
        p.product_name.length > maxLength
          ? p.product_name.substring(0, maxLength) + '...'
          : p.product_name
      return {
        value: p.asin,
        label: trimmedProductName + ' | ' + p.asin + ' | ' + p.sku,
      }
    })
  }

  const failedSpecUpdate = () => {
    setFailedSpec([])
  }
  return (
    <>
      <div className="py-8 px-2 w-[96%] mx-auto">
        <div className="flex flex-col items-start w-full">
          <div className="flex flex-col gap-1 mb-6 w-full">
            <h2
              className={`text-3xl tracking-normal font-space-grotesk ${
                lightMode ? 'text-slate-800' : 'text-white'
              }`}
            >
              Creatives
            </h2>
            <p className="text-muted-foreground font-geist">
              Add creatives for Autron to use in Sponsored Brands and Sponsored
              Display campaigns.
            </p>
          </div>
          {brandsIsLoading ? (
            <Card className="overflow-visible h-auto w-full lg:w-[700px]">
              <CardContent className="pt-6 overflow-visible">
                <div className="space-y-4 py-6">
                  <Skeleton className="h-4 w-full" />
                  <Skeleton className="h-4 w-3/4" />
                  <Skeleton className="h-4 w-1/2" />
                  <div className="grid grid-cols-3 gap-4 pt-4 w-full">
                    <Skeleton className="h-40 w-full" />
                    <Skeleton className="h-40 w-full" />
                    <Skeleton className="h-40 w-full" />
                  </div>
                  <div className="grid grid-cols-3 gap-4 pt-4 w-full">
                    <Skeleton className="h-40 w-full" />
                    <Skeleton className="h-40 w-full" />
                    <Skeleton className="h-40 w-full" />
                  </div>
                </div>
              </CardContent>
            </Card>
          ) : (
            <Tabs
              value={horizontalTabs}
              onValueChange={(value) => setHorizontalTabs(value)}
              // className={`flex flex-col gap-0`}
              className="w-full"
            >
              <TabsList className="w-[200px]">
                <TabsTrigger
                  value="brand"
                  onClick={() => history.push('/admin/creatives/brand')}
                  className="w-1/2"
                >
                  Brand
                </TabsTrigger>
                <TabsTrigger
                  value="video"
                  onClick={() => history.push('/admin/creatives/video')}
                  className="w-1/2"
                >
                  Video
                </TabsTrigger>
              </TabsList>
              <TabsContent value="brand">
                <BrandCreatives
                  brands={brands}
                  brandUpdate={handleBrandUpdate}
                  uploadingLogo={uploadingLogo}
                  updatingLogo={updatingLogo}
                />
              </TabsContent>
              <TabsContent value="video">
                <VideoCreatives
                  brands={brands}
                  products={products}
                  failedSpec={failedSpec}
                  mediaUpdate={handleBrandUpdate}
                  uploadingMedia={uploadingMedia}
                  updatingMedia={updatingMedia}
                  failedSpecUpdate={failedSpecUpdate}
                  productsDataObject={productsDataObject}
                />
              </TabsContent>
            </Tabs>
          )}
        </div>
      </div>
    </>
  )
}

export default Creatives

import React, { useState, useContext } from 'react'

import { useHistory, useParams } from 'react-router-dom'
import InsightsDashboard from './InsightsDashboard'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from 'shadcn-components/ui/tabs'
import { Badge } from 'shadcn-components/ui/badge'
import ThemeContext from 'services/providers/ThemeContext'

const Insights = ({ user }) => {
  const { lightMode } = useContext(ThemeContext)
  const getCustomDashboardId = (user) => {
    // Custom dashboard IDs based on user.company.public_id
    const customDashboardIds = {
      // Add custom dashboard IDs here in the format: 'public_id': 'dashboard_id'
      // '0462b7b8-7ac5-4bac-9b80-313792ed50b7': 17, // SimpleIda Dev
      'c84a371b-6f1f-4e88-86ac-0458572e1f8d': 17, // Element 26
    }
    return customDashboardIds[user.company.public_id] || null
  }
  const insights = [
    {
      name: 'Sales & Traffic',
      slug: 'sales-and-traffic',
      description:
        'The Sales & Traffic dashboard presents a comprehensive snapshot of sales metrics, user behavior patterns, and product popularity over time. With in-depth category insights and platform usage data, it offers a clear perspective on the pulse and trajectory of your business operations.',
      description2: '',
      dashboardId: 2,
    },
    {
      name: 'Profitability',
      slug: 'profitability',
      description:
        "The Profitability dashboard displays financial metrics, category-specific profitability, and product earnings. With insights by category and ASIN, it provides clarity on your business's financial health. Use this data for strategic planning, optimizing costs, and enhancing profitability.",
      description2:
        "Note: For precise profit calculations, add a 'Unit Cost' to your <a href='/admin/products'>products</a>. It might take 24 hours for updates to reflect on the dashboard. Profit metrics are accurate when filtering by Category, Parent ASIN, or ASIN only if Autron manages your entire advertising.",
      dashboardId: 3,
    },
    {
      name: 'Advertising',
      slug: 'advertising',
      description:
        "The Advertising dashboard delivers insights on ad performance, showcasing metrics like Ad Sales, Spend, and ACOS. With data segmented by campaign types, products, and categories, it's your guide to better advertising decisions. Compare Autron-managed versus Non-Autron campaigns to fine-tune your advertising strategies effectively.",
      description2:
        "Note: The Category, Parent ASIN, and ASIN filters are accurate solely for advertising data under Autron's management. It is recommended to use these filters after Autron has overseen your advertising account for several months to obtain precise advertising insights.",
      dashboardId: 8,
    },
    {
      name: 'Search Terms',
      slug: 'search-terms',
      description:
        'The Search Term dashboard unveils the impact of specific search terms on sales. It lays out essential metrics like Impressions, Ad Spend, Attributed Sales, ACOS, and Conversion Rate. Navigate through the top-contributing terms to refine listing keyword strategies. Perfect for capturing consumer search behavior and adjusting to market trends.',
      description2: '',
      dashboardId: 4,
    },
    {
      name: 'Customers',
      slug: 'customers',
      description:
        'The Customer dashboard offers a comprehensive view of your customer trends, differentiating between new and returning customers over selected periods. Explore geographic insights, analyze cohort-based lifetime value, and understand purchase behaviors. With metrics such as CAC, Lifetime Value, and Breakeven ACOS, this dashboard equips you to refine customer retention efforts and optimize sales strategies.',
      description2:
        "Note: For accurate LTV and Breakeven ACOS, add a 'Unit Cost' to your <a href='/admin/products'>products</a>. It might take 24 hours for updates to reflect on the dashboard. Cohort metrics are accurate when filtering by Category, Parent ASIN, or ASIN only if Autron manages your entire advertising.",
      dashboardId: 7,
    },
    {
      name: 'Benchmarks',
      slug: 'benchmarks',
      description:
        'The Benchmarks dashboard delivers essential insights into how your brand stacks up throughout the entire customer journey on Amazon, compared to your competition. With unique weekly competitive indexes, you can track meaningful progress. Plus, Sponsored Brands campaign benchmarks spotlight your advertising performance against competitors in your key categories.',
      description2: '',
      dashboardId: 16,
    },
    {
      name: 'Custom',
      slug: 'custom',
      description:
        'The Custom dashboard is crafted to meet your specific needs, providing in-depth insights, data visualizations, and comprehensive reports.',
      description2: '',
      dashboardId: getCustomDashboardId(user),
    },
  ]

  const params = useParams()
  const history = useHistory()

  const [horizontalTabs, setHorizontalTabs] = useState(
    params.type ? params.type : insights[0].slug
  )

  const [activeTab, setActiveTab] = useState(insights[0].slug)
  return (
    <>
      <div className="py-8 px-2 overflow-hidden w-[96%] mx-auto">
        <div className="flex justify-between items-start mb-6">
          <div className="flex flex-col gap-1">
            <h2
              className={`text-3xl tracking-normal font-space-grotesk ${
                lightMode ? 'text-slate-800' : 'text-white'
              }`}
            >
              Insights
            </h2>
            <p className="text-muted-foreground font-geist">
              Comprehensive analytics tool to strategize your Amazon business
              with data-driven insights.
            </p>
          </div>
        </div>
        <Tabs value={horizontalTabs}>
          <div className="overflow-x-scroll scrollbar-hide h-16 flex items-center -mb-3 -mt-3">
            <TabsList>
              {insights.map((insight) => (
                <TabsTrigger
                  key={insight.slug}
                  value={insight.slug}
                  className="relative flex-grow basis-1/2 sm:basis-1/3 md:basis-1/6 data-[state=active]:bg-background font-geist font-medium tracking-normal"
                  onClick={(e) => {
                    setHorizontalTabs(insight.slug)
                    history.push('/admin/insights/' + insight.slug)
                  }}
                >
                  <span className="truncate">{insight.name}</span>
                  {['benchmarks', 'custom'].includes(insight.slug) &&
                    horizontalTabs !== insight.slug && (
                      <Badge className="px-2 py-0.25 dark:border-yellow-100 dark:bg-transparent dark:text-yellow-200 bg-yellow-200 hover:bg-yellow-200 text-slate-600 font-semibold absolute -top-3 -right-6">
                        New
                      </Badge>
                    )}
                </TabsTrigger>
              ))}
            </TabsList>
          </div>
          {insights.map((insight) => (
            <TabsContent key={insight.slug} value={insight.slug}>
              <InsightsDashboard
                insight={insight}
                seller={user.primary_seller}
              />
            </TabsContent>
          ))}
        </Tabs>
      </div>
    </>
  )
}

export default Insights

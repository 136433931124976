import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
  useContext,
} from 'react'
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useExpanded,
  useRowSelect,
} from 'react-table'
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from 'shadcn-components/ui/pagination'
import { ChevronsLeft, ChevronsRight, Search, X } from 'lucide-react'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'shadcn-components/ui/table'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'shadcn-components/ui/select'
import { Label } from 'shadcn-components/ui/label'
import { LoadingSpinner } from 'shadcn-components/ui/spinner'
import FilterUI from 'shadcn-components/ui/filter-ui'
import { Skeleton } from 'shadcn-components/ui/skeleton'
import FilterContext from 'services/providers/FilterContext'
import TableSearchContext from 'services/providers/TableSearchContext'

const GlobalFilter = React.memo(
  ({
    onSearch,
    searchInputPlaceholder,
    tableSearchKey,
    queryParam,
    setQueryParam,
  }) => {
    const inputRef = useRef(null)
    const timeoutRef = useRef(null)
    const {
      searchValues,
      searchQueryParams,
      updateSearchValue,
      updateSearchQueryParams,
      clearSearchValues,
    } = useContext(TableSearchContext)
    const [localValue, setLocalValue] = useState(
      searchValues[tableSearchKey] || ''
    )
    const [isTyping, setIsTyping] = useState(false)

    const handleChange = (e) => {
      const value = e.target.value
      setLocalValue(value)
      setIsTyping(true)
      updateSearchValue(tableSearchKey, value)

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      timeoutRef.current = setTimeout(() => {
        setIsTyping(false)
        if (!value || value.length >= 2) {
          onSearch(value)
        }
      }, 800)
    }

    useEffect(() => {
      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
        }
      }
    }, [])

    const handleClear = () => {
      setLocalValue('')
      setIsTyping(false)
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      // First clear the search value in context
      updateSearchValue(tableSearchKey, '')

      // Clear search query params and preserve other existing params
      const newParams = { ...queryParam }
      // Remove any gfilter parameters
      Object.keys(newParams).forEach((key) => {
        if (key.startsWith('gfilter[')) {
          delete newParams[key]
        }
      })

      // Update both contexts
      clearSearchValues()
      setQueryParam(newParams)
      onSearch('')
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }

    return (
      <div className="w-full max-w-md relative">
        <div
          className={`flex items-center border rounded-md px-3 transition-shadow duration-200 ${
            Object.values(searchValues[tableSearchKey] || {}).some(
              (value) => value
            )
              ? 'ring-2 ring-primary ring-offset-2'
              : ''
          } focus-within:ring-2 focus-within:ring-primary focus-within:ring-offset-2`}
        >
          <Search className="mr-2 h-4 w-4 shrink-0 text-muted-foreground" />
          <input
            ref={inputRef}
            type="text"
            placeholder={searchInputPlaceholder}
            value={localValue}
            onChange={handleChange}
            className="flex h-10 w-full bg-transparent py-3 text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50 border-0 focus:ring-0 focus:ring-offset-0"
            style={{ boxShadow: 'none' }}
          />
          {localValue && (
            <button
              onClick={handleClear}
              className="focus:outline-none"
              aria-label="Clear search"
            >
              <X className="h-4 w-4 text-muted-foreground hover:text-foreground transition-colors duration-200" />
            </button>
          )}
        </div>
      </div>
    )
  }
)

const LoadingTableRow = () => (
  <TableRow className="h-[73px]">
    {[15, 25, 20, 15, 15, 10].map((width, i) => (
      <TableCell
        key={i}
        className="align-middle py-4"
        style={{ width: `${width}%` }}
      >
        <Skeleton className="h-6 w-full" />
      </TableCell>
    ))}
  </TableRow>
)

const CustomTable = React.memo(
  ({
    columns,
    data,
    loading,
    total,
    pageCount: controlledPageCount,
    queryParam,
    setQueryParam,
    expandRowsByDefault = false,
    onRowClick = null,
    showGlobalSearch = true,
    setSelectedRows,
    hiddenColumns = [],
    filterOptions = null,
    filterStates = null,
    searchInputPlaceholder = 'Search...',
    tableSearchKey = 'default',
  }) => {
    const lastSearchRef = useRef('')
    const { filterQueryParams } = useContext(FilterContext)
    const { searchQueryParams, updateSearchQueryParams } =
      useContext(TableSearchContext)

    const handleSearch = useCallback(
      (value) => {
        if (!setQueryParam) return

        // If the search value hasn't changed, don't update
        if (value === lastSearchRef.current) return

        lastSearchRef.current = value

        const searchKey = columns
          .filter((column) => Number.isFinite(column.gfilter))
          .sort((a, b) => a.gfilter - b.gfilter)
          .map((col) => col.accessor)
          .toString()

        // Preserve existing query parameters from both contexts
        const newParams = {
          ...filterQueryParams, // Keep filter parameters
          ...queryParam,
          page: 1,
        }

        if (value) {
          newParams[`gfilter[${searchKey}][ilike]`] = `%${value}%`
        } else {
          // Only remove the gfilter parameter
          delete newParams[`gfilter[${searchKey}][ilike]`]
        }

        // Store only the gfilter-related parameters in search context
        const searchParams = value
          ? {
              [`gfilter[${searchKey}][ilike]`]: `%${value}%`,
            }
          : {}
        updateSearchQueryParams(tableSearchKey, searchParams)
        setQueryParam(newParams)
      },
      [setQueryParam, columns, queryParam, filterQueryParams]
    )

    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      selectedFlatRows,
      canPreviousPage,
      canNextPage,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      toggleAllRowsExpanded,
      state: { pageIndex, pageSize, sortBy },
    } = useTable(
      {
        columns,
        data,
        initialState: {
          pageIndex: queryParam ? queryParam.page - 1 : 0,
          pageSize: queryParam ? queryParam.per_page : total,
          hiddenColumns,
        },
        manualSortBy: true,
        manualPagination: true,
        autoResetPage: false, // see https://react-table.tanstack.com/docs/api/usePagination#table-options
        pageCount: controlledPageCount,
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      useExpanded,
      usePagination,
      useRowSelect
    )

    useMemo(
      () => toggleAllRowsExpanded(expandRowsByDefault),
      [toggleAllRowsExpanded, expandRowsByDefault]
    )
    // Update effect to ignore global filter changes
    useEffect(() => {
      if (!setQueryParam) return

      const queryParams = {
        ...(filterQueryParams || {}), // Preserve filter context if it exists
        ...(searchQueryParams[tableSearchKey] || {}), // Preserve search context if it exists
        page: pageIndex + 1,
        per_page: pageSize,
      }

      // Add sorting if present
      if (sortBy.length > 0) {
        sortBy.forEach((field) => {
          const key = 'sort['.concat(field.id).concat(']')
          queryParams[key] = field.desc ? 'desc' : 'asc'
        })
      }
      setQueryParam(queryParams)
    }, [
      columns,
      pageIndex,
      pageSize,
      sortBy,
      setQueryParam,
      filterQueryParams,
      searchQueryParams,
      tableSearchKey,
    ])

    useEffect(() => {
      if (setSelectedRows) {
        setSelectedRows(selectedFlatRows.map((row) => row.original))
      }
    }, [selectedFlatRows, setSelectedRows])

    const results = () => {
      let start = (pageIndex + 1) * pageSize - (pageSize - 1)
      let finish = Math.min((pageIndex + 1) * pageSize, total)
      return (
        (finish === 0 ? '0' : start + (start === finish ? '' : '-' + finish)) +
        ' of ' +
        total +
        ' results'
      )
    }

    const disabledClasses =
      'opacity-50 cursor-not-allowed pointer-events-none border border-input'
    const activeClasses = 'cursor-pointer font-semibold border border-input'

    return (
      <>
        <div className="space-y-4 sm:mt-0 font-geist">
          {queryParam && showGlobalSearch && (
            <>
              <div className="flex flex-col lg:flex-row gap-4">
                <GlobalFilter
                  onSearch={handleSearch}
                  searchInputPlaceholder={searchInputPlaceholder}
                  tableSearchKey={tableSearchKey}
                  queryParam={queryParam}
                  setQueryParam={setQueryParam}
                />
                {filterOptions && (
                  <FilterUI
                    filtersObject={filterOptions}
                    filterStates={filterStates}
                    setQueryParam={setQueryParam}
                    queryParam={queryParam}
                    tableSearchKey={tableSearchKey}
                  />
                )}
              </div>
            </>
          )}
          <div className="rounded-md">
            <Table {...getTableProps()}>
              <TableHeader className="font-geist font-regular tracking-normal">
                {headerGroups.map((headerGroup) => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <TableHead
                        {...(column.sortable
                          ? column.getHeaderProps(column.getSortByToggleProps())
                          : column.getHeaderProps())}
                        className={loading ? 'w-[auto]' : ''}
                      >
                        {column.render('Header')}
                        {column.sortable && column.canSort && (
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="h-4 w-4 ml-2 inline"
                            >
                              <path
                                d="m21 16-4 4-4-4"
                                className={
                                  column.isSorted && column.isSortedDesc
                                    ? 'stroke-primary'
                                    : 'stroke-muted-foreground'
                                }
                                strokeWidth={
                                  column.isSorted && column.isSortedDesc
                                    ? '2.8'
                                    : '2'
                                }
                              />
                              <path
                                d="M17 20V4"
                                className={
                                  column.isSorted && column.isSortedDesc
                                    ? 'stroke-primary'
                                    : 'stroke-muted-foreground'
                                }
                                strokeWidth={
                                  column.isSorted && column.isSortedDesc
                                    ? '2.8'
                                    : '2'
                                }
                              />
                              <path
                                d="m3 8 4-4 4 4"
                                className={
                                  column.isSorted && !column.isSortedDesc
                                    ? 'stroke-primary'
                                    : 'stroke-muted-foreground'
                                }
                                strokeWidth={
                                  column.isSorted && !column.isSortedDesc
                                    ? '2.8'
                                    : '2'
                                }
                              />
                              <path
                                d="M7 4v16"
                                className={
                                  column.isSorted && !column.isSortedDesc
                                    ? 'stroke-primary'
                                    : 'stroke-muted-foreground'
                                }
                                strokeWidth={
                                  column.isSorted && !column.isSortedDesc
                                    ? '2.8'
                                    : '2'
                                }
                              />
                            </svg>
                          </span>
                        )}
                      </TableHead>
                    ))}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody
                {...getTableBodyProps()}
                className="font-geist font-regular tracking-normal"
              >
                {loading
                  ? Array(data.length != 0 ? data.length : 10)
                      .fill(0)
                      .map((_, index) => <LoadingTableRow key={index} />)
                  : rows.map((row) => {
                      prepareRow(row)
                      return (
                        <TableRow
                          {...row.getRowProps()}
                          onClick={(e) =>
                            onRowClick ? onRowClick(row, e) : {}
                          }
                          className={
                            onRowClick
                              ? 'cursor-pointer hover:bg-slate-50 dark:hover:bg-slate-900'
                              : ''
                          }
                        >
                          {row.cells.map((cell) => (
                            <TableCell {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </TableCell>
                          ))}
                        </TableRow>
                      )
                    })}
              </TableBody>
            </Table>
          </div>
          {queryParam && (
            <div className="flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-between">
              <div className="flex flex-col items-center justify-center gap-4">
                <p className="text-sm text-muted-foreground flex gap-2 items-center justify-center">
                  {loading ? (
                    <>
                      <LoadingSpinner className="w-4 h-4" />
                      <span>Loading...</span>
                    </>
                  ) : (
                    <span className="text-center text-wrap sm:text-nowrap">
                      {results()}
                    </span>
                  )}
                </p>
                <div className="flex items-center sm:hidden gap-2">
                  <Label htmlFor="per-page" className="text-sm text-nowrap">
                    Rows per page:
                  </Label>
                  <Select
                    value={pageSize.toString()}
                    onValueChange={(value) => setPageSize(Number(value))}
                  >
                    <SelectTrigger className="w-[70px]">
                      <SelectValue placeholder={pageSize} />
                    </SelectTrigger>
                    <SelectContent>
                      {![10, 25, 50, 100, 500].includes(pageSize) && (
                        <SelectItem key={pageSize} value={pageSize.toString()}>
                          {pageSize}
                        </SelectItem>
                      )}
                      {[10, 25, 50, 100, 500].map((size) => (
                        <SelectItem key={size} value={size.toString()}>
                          {size}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
              <div className="flex items-center gap-10">
                <div className="hidden sm:flex items-center gap-2">
                  <Label htmlFor="per-page" className="text-sm text-nowrap">
                    Rows per page:
                  </Label>
                  <Select
                    value={pageSize.toString()}
                    onValueChange={(value) => setPageSize(Number(value))}
                  >
                    <SelectTrigger className="w-[70px]">
                      <SelectValue placeholder={pageSize} />
                    </SelectTrigger>
                    <SelectContent>
                      {![10, 25, 50, 100, 500].includes(pageSize) && (
                        <SelectItem key={pageSize} value={pageSize.toString()}>
                          {pageSize}
                        </SelectItem>
                      )}
                      {[10, 25, 50, 100, 500].map((size) => (
                        <SelectItem key={size} value={size.toString()}>
                          {size}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                <Pagination>
                  <PaginationContent>
                    <PaginationItem>
                      <PaginationLink
                        onClick={() => gotoPage(0)}
                        aria-disabled={!canPreviousPage}
                        className={
                          !canPreviousPage ? disabledClasses : activeClasses
                        }
                      >
                        <ChevronsLeft className="h-4 w-4" />
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationPrevious
                        onClick={previousPage}
                        className={
                          !canPreviousPage ? disabledClasses : activeClasses
                        }
                        aria-disabled={!canPreviousPage}
                      />
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationNext
                        onClick={nextPage}
                        className={
                          !canNextPage ? disabledClasses : activeClasses
                        }
                        aria-disabled={!canNextPage}
                      />
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        onClick={() => gotoPage(pageCount - 1)}
                        className={
                          !canNextPage ? disabledClasses : activeClasses
                        }
                        aria-disabled={!canNextPage}
                      >
                        <ChevronsRight className="h-4 w-4" />
                      </PaginationLink>
                    </PaginationItem>
                  </PaginationContent>
                </Pagination>
              </div>
            </div>
          )}
        </div>
      </>
    )
  }
)

export default CustomTable

import React, { useContext, useEffect, useState } from 'react'
import ThemeContext from 'services/providers/ThemeContext'
import { Skeleton } from 'shadcn-components/ui/skeleton'
import { Card, CardContent } from 'shadcn-components/ui/card'
import IframeResizer from 'iframe-resizer-react'
import apiAuth from 'services/api/Auth'
import { useQuery, useQueryClient } from 'react-query'

const Agent = () => {
  const { lightMode } = useContext(ThemeContext)
  const [loading, setLoading] = useState(true)

  // Use /auto-login to bypass rendering /login during the iframeUrl loading delay.
  const agentUrl = process.env.REACT_APP_AGENT_APP_BASE_URL + '/auto-login'
  const [iframeUrl, setIframeUrl] = useState(agentUrl)

  const queryClient = useQueryClient()

  useQuery('GET/auth/agent/url', () => apiAuth.agent_url(), {
    staleTime: 10 * 60 * 1000, // 10 minutes since iframe_url is only valid for 11 minutes
    onSuccess: (response) => {
      if (response && response.status < 300) {
        setIframeUrl(
          `${response.data.iframe_url}&theme=${lightMode ? 'light' : 'dark'}`
        )
      }
    },
  })

  useEffect(() => {
    const iframeUrlCache = queryClient.getQueryData(['GET/auth/agent/url'])

    if (iframeUrlCache && iframeUrlCache.data?.iframe_url) {
      setIframeUrl(
        `${iframeUrlCache.data.iframe_url}&theme=${
          lightMode ? 'light' : 'dark'
        }`
      )
    }
  }, [queryClient, lightMode])

  useEffect(() => {
    setIframeUrl((prevUrl) => {
      const url = new URL(prevUrl)
      url.searchParams.set('theme', lightMode ? 'light' : 'dark')
      return url.toString()
    })
  }, [lightMode])

  return (
    <div className="py-8 px-2 overflow-hidden w-[96%] mx-auto shadow-none border-none">
      <div className="flex justify-between items-start mb-6">
        <div className="flex flex-col gap-1">
          <h2
            className={`text-3xl tracking-normal font-space-grotesk ${
              lightMode ? 'text-slate-800' : 'text-white'
            }`}
          >
            Agent
          </h2>
          <p className="text-muted-foreground font-geist tracking-normal">
            Your dedicated Account Manager keeps you informed, answers your
            questions, provides real-time insights, and delivers optimisation
            tips—just like having a personal expert by your side.
          </p>
        </div>
      </div>
      <Card>
        <CardContent className="pt-6 overflow-visible">
          {loading && (
            <div className="space-y-4 px-8 py-6">
              <Skeleton className="h-4 w-full" />
              <Skeleton className="h-4 w-3/4" />
              <Skeleton className="h-4 w-1/2" />
              <div className="grid grid-cols-3 gap-4 pt-4">
                <Skeleton className="h-40 w-full" />
                <Skeleton className="h-40 w-full" />
                <Skeleton className="h-40 w-full" />
              </div>
              <div className="grid grid-cols-3 gap-4 pt-4">
                <Skeleton className="h-40 w-full" />
                <Skeleton className="h-40 w-full" />
                <Skeleton className="h-40 w-full" />
              </div>
              <div className="grid grid-cols-3 gap-4 pt-4">
                <Skeleton className="h-40 w-full" />
                <Skeleton className="h-40 w-full" />
                <Skeleton className="h-40 w-full" />
              </div>
            </div>
          )}
          {iframeUrl !== agentUrl && (
            <IframeResizer
              src={iframeUrl}
              title="Autron Agent"
              onLoad={() => {
                setLoading(false)
              }}
              hidden={loading}
              style={{
                width: '1px',
                minWidth: '100%',
                minHeight: '40rem',
              }}
            />
          )}
        </CardContent>
      </Card>
    </div>
  )
}

export default Agent

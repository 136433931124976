import React, { useState } from 'react'
import { FilterToolbar } from './filter-toolbar'

export default function FilterUI({
  filtersObject,
  filterStates,
  setQueryParam,
  queryParam,
  tableSearchKey,
}) {
  const [searchValue, setSearchValue] = useState('')
  // const [filterStates, setFilterStates] = useState(
  //   Object.keys(filtersObject).reduce((acc, key) => {
  //     acc[key] = {
  //       values: new Set(),
  //       setValues: (newValues) => {
  //         setFilterStates((prev) => ({
  //           ...prev,
  //           [key]: {
  //             ...prev[key],
  //             values: newValues,
  //           },
  //         }))
  //       },
  //     }
  //     return acc
  //   }, {})
  // )

  return (
    <FilterToolbar
      tableSearchKey={tableSearchKey}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      filtersObject={filtersObject}
      filterStates={filterStates}
      setQueryParam={setQueryParam}
      queryParam={queryParam}
    />
  )
}
